<template>
  <v-card>
    <v-card-text class="py-3">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between">
          <h1 class="page-title">USERS</h1>
          <v-btn text icon small color="gray" @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="IS_SYS_ADMIN">
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="toggleDialog('viewCreateEdit')"
          >
            <v-icon>mdi-plus</v-icon>Add
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.items"
            :server-items-length="dataTable.total"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 50, 100],
            }"
            :options.sync="dataTable.options"
            disable-sort
            @update:page="loadData()"
            @update:items-per-page="loadData()"
          >
            <template v-slot:[`item.locked`]="{ item }">
              <span class="badge bg-primary text-white" v-if="item.locked">Yes</span>
              <span class="badge bg-danger text-white" v-else>No</span>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <span class="badge bg-primary text-white" v-if="item.locked">Yes</span>
              <span class="badge bg-danger text-white" v-else>No</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon color="orange">
                <v-icon class="btn-action" small @click="viewdialogs(item)">mdi-eye</v-icon>
              </v-btn>
              <v-btn text icon color="blue">
                <v-icon class="btn-action" small @click="editdialogs(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon class="btn-action" small @click="processUser('delete', item.id)">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogs.viewCreateEdit" max-width="960" persistent>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="pr-0 pt-0 pl-3 pb-0 border-bottom">
              <span class="text-h5">{{ formAction + ' User' }}</span>
              <v-row class="m-0">
                <v-col cols="pull-right-10 p-2">
                  <v-btn
                    text
                    icon
                    small
                    color="gray"
                    class="float-right"
                    @click="toggleDialog('viewCreateEdit')"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.name"
                      :rules="error.name"
                      label="Name"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.email"
                      :rules="error.email"
                      type="email"
                      label="Email"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.username"
                      :rules="error.username"
                      label="Username"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.mobile_no"
                      type="text"
                      label="Mobile No."
                      max="11"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="form.department_id"
                      :items="dropdowns.departments"
                      :rules="error.department_id"
                      label="Department"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="form.job_title"
                      :rules="error.job_title"
                      label="Job Title"
                      hide-details="auto"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="3">
                    <v-switch
                      v-model="form.active"
                      label="active"
                      color="primary"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-switch
                      v-model="form.locked"
                      label="locked"
                      color="primary"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-switch
                      v-model="form.manager"
                      label="manager"
                      color="primary"
                      input-value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      outlined
                      dense
                      label="token expiration time"
                      v-model="form.token_expiration_time"
                      @keyup="convertTohrs()"
                    >
                      <template #prepend-inner>
                        <span class="pt-1">min : </span>
                      </template>
                      <template #append>
                        <span class="pt-1">hrs: {{ convertTohrs() }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-center">
              <v-row class="ma-1">
                <v-col cols="12">
                  <v-btn
                    class="float-center"
                    color="primary"
                    @click="EditUser()"
                  >
                    <span>{{ submitAction }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

export default {
  data: () => ({
    loaded: false,
    valid: false,
    action: 'Create',
    form: {
      name: null,
      password: null,
      password_confirmation: null,
      email: null,
      username: null,
      mobile_no: null,
      department_id: null,
      active: 1,
      locked: 0,
      job_title: null,
      manager: 0,
      token_expiration_time: 600,
    },
    error: {
      name: [(v) => !!v || 'Name is required'],
      email: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      username: [(v) => !!v || 'Username is required'],
      mobile_no: [(v) => !!v || 'Mobile is required'],
      password: [
        (v) => !!v || 'Password is required',
        // (v) => v.length >= 8 || 'Password must be greater than 8',
      ],
      password_confirmation: [
        (v) => !!v || 'Password is required',
        // (v) => v.length >= 8 || 'Password must be greater than 8',
        (v) => v === this.form.password || 'The password does not match.',
      ],
      department_id: [(v) => !!v || 'Department is required'],
      job_title: [(v) => !!v || 'Job Title is required'],
    },
    dataTable: {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Department', align: 'center', value: 'department_name' },
        { text: 'Locked', align: 'center', value: 'locked' },
        { text: 'Active', align: 'center', value: 'active' },
        { text: 'Actions', align: 'center', value: 'action' },
      ],
      items: [],
      options: {},
      total: 0,
      loading: false,
    },
    dropdowns: {
      departments: [],
    },
    dialogs: {
      viewCreateEdit: false,
    },
  }),
  props: {
    toggled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'USERS',
      'DEPARTMENTS',
      'IS_SYS_ADMIN'
    ]),
    formAction() {
      return this.action.charAt(0).toUpperCase() + this.action.substring(1).toLowerCase()
    },
    submitAction() {
      return this.action.toLowerCase() == 'create' ? 'Submit' : 'Update'
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('processUser', {
        method: 'get',
        params: {
          page: this.dataTable.options.page,
          itemsPerPage: this.dataTable.options.itemsPerPage,
        },
      });
    },
    async loadDropdowns() {
      await this.$store.dispatch('getDepartments', {
        url: 's/dropdown',
      });
    },
    async processUser(method = 'post', id = '') {
      let url = ''

      switch (method) {
        case 'get':
        case 'put':
        case 'delete':
          url = `/${id}`
          break
      }

      await this.$store.dispatch('processUser', {
        method: method,
        url: url,
        id: id,
      }).then(response => {
        console.log(response)
        swal.fire({
          title: response.title,
          text: response.text,
          icon: response.icon,
          timer: 3000,
        })

        this.loadData()
      })
    },
    convertTohrs(){
      let num = this.form.token_expiration_time?parseInt(this.form.token_expiration_time):0;
      let hr = num != NaN ?num / 60:0;
      return parseInt(hr);
    },
    toggleDialog(name) {
      this.dialogs[name] = !this.dialogs[name]
    },
    closeDialog() {
      this.$emit('closeDialog', false);
    },
  },
  async mounted() {
    await this.loadData();
    await this.loadDropdowns();
  },
  watch: {
    'USERS': {
      handler(val) {
        this.dataTable.items = val.data;
        this.dataTable.total = val.total;
      }
    },
    'DEPARTMENTS': {
      handler(val) {
        this.dropdowns.departments = val;
      },
    },
  },
};
</script>
